import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import './index.css';
import logoFullSize from './assets/img/logo-fullsize.png'

const Footer = () => {
  return (
    <footer>
      <p>&copy; PostBuddies.Com 2024</p>
      <p><a target="_blank" href="/privacy.html">Privacy Policy</a></p>
      <p>Background courtesy of http://background-tiles.com</p>
    </footer>
  )
}

const SubForm = () => {
  const [hasSub, setHasSub] = React.useState(false);

  if (hasSub) {
    return (
      <div>
        <p className="speechbubble left congrats">
          Thank you, you're now on the Waiting List!
        </p>
      </div>
    )
  }

  return (
    <div>
      <p>Want to show your support and be on our Waiting List? Just enter your email below and click "Add" now!</p>
      <form id="sub-form" onSubmit={(e) => {
        e.preventDefault();

        document.getElementById("sub-form").style.opacity = 0.5;
        document.getElementById("sub-form").style.pointerEvents = "none";

        fetch("https://postbuddies.com/api/addEmailToWaitingList", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: document.getElementById("sub-form-email-input").value
          }),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then(data => {
            if (data.error !== null) {
              alert(data.error)
              console.error("Error:", data.error);
            } else {
              setHasSub(true);
            }
          })
          .catch(error => {
            alert(error);
            console.error("Error:", error);
          });

      }} action="" method="post">
        <p><input id="sub-form-email-input" name="email" autoComplete='email' autoFocus type="email" placeholder="Your email..." required></input>&nbsp;<button>Add</button></p>
        <p style={{ fontSize: 12 }}><input type="checkbox" required></input> You confirm that you understand our <a target="_blank" href="/privacy.html">Privacy Policy</a>.</p>
      </form>
    </div>
  )
}

const Home = () => {

  return (
    <feature>
      <img src={logoFullSize} height="50px"></img>
      <p><em>Do you love to brighten up your social media posts on Facebook, or your messages, with joyful GIFs and thoughtful quotes? Well, meet your <strong>PostBuddies</strong>!</em></p>
      <ul style={{ textAlign: "left" }}>
        <li>
          <p><strong className='rainbow-text'>Free GIFs</strong> — daily, and as personalised and customisable as you wish</p>
        </li>
        <li>
          <p><strong className='rainbow-text'>Personalised "E-Cards"</strong> — send your friends and family sweet messages in an entertaining and interactive way, an everlasting gift that's sure to make them smile any time!</p>
        </li>
      </ul>
      <h2>All this and more... coming soon in 2025!</h2>
      <hr></hr>
      <SubForm></SubForm>
      <hr></hr>
      <div>
        <p className="speechbubble">
          P.S Don't forget to bookmark this webpage so you can check on it later!
        </p>
        <p className="speechbubble left dog">
          Thank you, and have a lovely day!
        </p>
      </div>
    </feature>
  )
}

const App = () => {
  return (
    <div>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={
            <feature>
              <h1>Page not found</h1>
            </feature>
          } />
        </Routes >
        <Footer />
      </main>
    </div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
